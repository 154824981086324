import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";

import PrimaryButton from "../Generic/PrimaryButton";
import { ARCHIVE, FILE, LINK } from "../../constants/OptionsConstants";

import { useLinkContext } from "../../context/useLinkContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import {
  showErrorNotification,
  showGenericError,
} from "../../helper/Notifications";
import InputFieldWithValidation from "../Generic/InputFieldWithValidation";
import { isURL } from "validator";
import DataNewLink from "../createNewLink/DataNewLink";
import SocialMediaNewLink from "../createNewLink/SocialMediaNewLink";
import DetailsNewLink from "../createNewLink/DetailsNewLink";
import { useOutletContext } from "react-router-dom";
import SecondaryButton from "../Generic/SecondaryButton";
import DeleteConfirmationChild from "../Generic/DeleteConfirmationWithChild";
import { copyToClipboard } from "../../helper/copy";
import { motion } from "framer-motion";
import _, { replace } from "lodash";
import { mapKeyPairValues } from "../../helper/Utils";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";

const EditLinkModal = ({
  isMoldaOpen,
  setIsModalOpen,
  selectedLink,
  handleCloseModal,
}) => {
  const { isPathAvailable, shouldRefreshLinksRef, updateLink, removeLink } =
    useLinkContext();
  const { selectedProject } = useInstanceContext();
  const { setLoading } = useOutletContext();
  const [domain, setDomain] = useState("https://" + selectedProject.domain);

  const disabledActions = !selectedLink.link.active;

  const currentLink = selectedLink.link;
  const [linkName, setLinkName] = useState(currentLink.name);
  const [path, setPath] = useState(currentLink.path);

  const [socialMediaTitle, setSocialMediaTitle] = useState(currentLink.title);
  const [socialMediaSubTitle, setSocialMediaSubTitle] = useState(
    currentLink.subtitle
  );
  const [imageFile, setImageFile] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [imageType, setImageType] = useState(LINK);
  const [tagInput, setTagInput] = useState("");
  const [imagePreview, setImagePreview] = useState(currentLink.image);
  const [replaceImage, setReplaceImage] = useState(false);

  const [pathAvailable, setPathAvailable] = useState(true);

  const [tagsList, setTagsList] = useState(currentLink.tags);

  const [keyValuePair, setKeyValuePair] = useState(
    mapKeyPairValues(currentLink.data) || []
  );
  const [initialKeyPair, setInitialKeyPair] = useState(
    mapKeyPairValues(currentLink.data) || []
  );

  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(false);

  const ref = useRef();

  const inputContainerStyle = {
    background: "none",
    border: "none",
  };

  const inputCustomStyle = {
    background: "none",
    overflow: "hidden",
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    color: lightThemeColors.darkColor,
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "27px",
  };

  const handleSetImageType = (imageType) => {
    if (imageType === FILE) {
      setImageType(imageType);
    }

    if (imageType === LINK) {
      setImageType(imageType);
      setImageFile(null);
    }
  };

  const handleSetImage = (e) => {
    setImageFile(e.currentTarget.files[0]);
    let preview = createPreview(e.currentTarget.files[0]);
    setImagePreview(preview);
  };

  const createPreview = (imageInput) => {
    if (!imageInput) {
      return;
    }
    let preview = URL.createObjectURL(imageInput);
    return preview;
  };

  const hasDuplicateKeys = (arr) => {
    const seenKeys = new Set();
    return arr.some((item) => {
      if (seenKeys.has(item.key)) {
        return true;
      }
      seenKeys.add(item.key);
      return false;
    });
  };

  const handleEditLink = () => {
    let dictionar = {};

    const formData = new FormData();
    formData.append("path", path);
    formData.append("name", linkName);

    if (socialMediaTitle != null && socialMediaTitle !== "") {
      formData.append("title", socialMediaTitle);
    }

    if (socialMediaSubTitle != null && socialMediaSubTitle !== "") {
      formData.append("subtitle", socialMediaSubTitle);
    }

    if (imageType === FILE) {
      if (imageFile) {
        formData.append("image", imageFile);
      }
    }

    if (imageType === LINK) {
      if (imageLink != currentLink.image) {
        formData.append("image_url", imageLink);
      }
    }

    if (tagsList.length > 0) {
      formData.append("tags", JSON.stringify(tagsList));
    }

    keyValuePair.forEach((item) => {
      if (item.value != "" && item.key != "") {
        Object.assign(dictionar, { [item.key]: item.value });
      }
    });

    if (Object.keys(dictionar).length > 0) {
      formData.append("data", JSON.stringify(dictionar));
    }

    if (hasDuplicateKeys(keyValuePair)) {
      showErrorNotification("You have duplicate keys");
    } else {
      setLoading(true);
      updateLink(
        selectedProject.id,
        currentLink.id,
        formData,
        (response) => {
          shouldRefreshLinksRef.current = true;
          handleCloseModal();
          setLoading(false);
        },
        () => {
          showGenericError();
          setLoading(false);
        }
      );
    }
  };

  const handleArchiveLink = () => {
    setLoading(true);
    removeLink(
      selectedProject.id,
      currentLink.id,
      (response) => {
        shouldRefreshLinksRef.current = true;
        handleCloseModal();
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const disabledCreateButton = () => {
    let validImageLink = true;

    if (imageType === FILE) {
      if (imageFile) {
        validImageLink = false;
      }
    } else if (imageLink != "") {
      validImageLink = isURL(imageLink);
    }

    if (
      currentLink.name != linkName ||
      (currentLink.path != path && pathAvailable) ||
      currentLink.title != socialMediaTitle ||
      currentLink.subtitle != socialMediaSubTitle ||
      imageLink != "" ||
      !validImageLink ||
      !_.isEqual(currentLink.tags, tagsList) ||
      !_.isEqual(initialKeyPair, keyValuePair)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSetReplaceImage = () => {
    setReplaceImage(true);
    setImageFile(null);
    setImageLink("");
  };

  const editLinkContent = (
    <>
      <ConfigList>
        <LinkPathWrapper>
          <LinkWrapper>
            <p>{domain}</p>
          </LinkWrapper>
          <PathWrapper>
            <InputFieldWithValidation
              style={{ margin: 0, borderRadiusTopRight: "10px" }}
              inputCustomStyle={inputCustomStyle}
              inputContainerStyle={inputContainerStyle}
              inputValue={path}
              valid={pathAvailable}
              readOnly={disabledActions}
              handleInputChange={(e) => setPath(e.currentTarget.value)}
              inputWrapperStyle={{ gap: 0 }}
              // customButton={
              //   <h1 style={{ color: lightThemeColors.primaryColor, cursor: "pointer", margin: 0 }}
              //     onClick={() => copyToClipboard(domain + "/" + path)}>
              //     Copy
              //   </h1>}
            />
          </PathWrapper>
          <SecondaryButton
            style={{ marginLeft: "10px" }}
            bgVariant={"transparent"}
            onClick={() => copyToClipboard(domain + "/" + path)}
            text={"Copy"}
            icon={<CopyIcon />}
          />
        </LinkPathWrapper>
        <DetailsNewLink
          linkName={linkName}
          setLinkName={setLinkName}
          noLabel
          tagInput={tagInput}
          setTagInput={setTagInput}
          tagsList={tagsList}
          setTagsList={setTagsList}
          disabledActions={disabledActions}
        />
        <SocialMediaNewLink
          socialMediaTitle={socialMediaTitle}
          setSocialMediaTitle={setSocialMediaTitle}
          socialMediaSubTitle={socialMediaSubTitle}
          setSocialMediaSubTitle={setSocialMediaSubTitle}
          handleSetImage={handleSetImage}
          imageLink={imageLink}
          imageFile={imageFile}
          setImageLink={setImageLink}
          setImageFile={setImageFile}
          imageType={imageType}
          setImageType={handleSetImageType}
          domain={domain}
          path={path}
          imagePreview={imagePreview}
          noLabel
          disabledActions={disabledActions}
          replaceImage={replaceImage}
          handleSetReplaceImage={handleSetReplaceImage}
        />
        <DataNewLink
          keyValuePair={keyValuePair}
          setKeyValuePair={setKeyValuePair}
          noLabel
          disabledActions={disabledActions}
        />
        {!disabledActions && (
          <PrimaryButton
            styled={{ width: "100%", marginTop: "auto", padding: "12px" }}
            text={"Edit Link"}
            onClick={() => handleEditLink()}
            disabled={disabledCreateButton() || disabledActions}
          />
        )}
        {!disabledActions && (
          <DeleteConfirmationChild
            variant={ARCHIVE}
            open={archiveConfirmationOpen}
            setOpen={setArchiveConfirmationOpen}
            action={handleArchiveLink}
          >
            <SecondaryButton
              style={{ width: "100%", padding: "11px" }}
              text={"Archive Link"}
              disabled={disabledActions}
              onClick={() => setArchiveConfirmationOpen(true)}
              variant={lightThemeColors.authentication.inputErrorColor}
            />
          </DeleteConfirmationChild>
        )}
      </ConfigList>
    </>
  );

  // useEffect(() => {
  //     const checkIfClickedOutside = (e) => {
  //         if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
  //             setIsModalOpen(false);
  //         }
  //     };
  //     document.addEventListener("mousedown", checkIfClickedOutside);
  //     return () => {
  //         // Cleanup the event listener
  //         document.removeEventListener("mousedown", checkIfClickedOutside);
  //     };
  // }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    if (path != currentLink.path) {
      const timeout = setTimeout(() => {
        isPathAvailable(
          selectedProject.id,
          path,
          (response) => {
            const available = response.data.available;
            setPathAvailable(available);
            if (!available) {
              showErrorNotification("Path is not available");
            }
          },
          (error) => {
            setPathAvailable(false);
            console.log(error);
          }
        );
      }, 250);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [path]);

  useEffect(() => {
    if (imageType === LINK) {
      if (imageLink != "") {
        setImagePreview(imageLink);
      }
    } else {
      if (imageFile != null) {
        let preview = createPreview(imageFile);
        setImagePreview(preview);
      }
    }
  }, [imageType, imageLink, imageFile]);

  return (
    <FullscreenContainer ref={ref}>
      <InviteModal
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <Line>
          <HeadWrapper>
            {disabledActions ? (
              <h1>Preview Archived Link</h1>
            ) : (
              <h1>Edit Link</h1>
            )}
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>
        {editLinkContent}
      </InviteModal>
    </FullscreenContainer>
  );
};

export default EditLinkModal;
const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3;
`;
const expandAnimation = keyframes`
0% { transform:translateX(100%)}

100% {
   transform: translateX(0)
}

`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 750px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px 0;
  gap: 40px;
  overflow: auto;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0 40px;
`;

const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 0 40px 30px 40px;
`;
const LinkPathWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border-radius: 5px;
  overflow: hidden;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background: ${lightThemeColors.sidebar.linkBg};
  width: 50%;
  min-height: 60px;
  p {
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${lightThemeColors.primaryColor};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }
`;
const PathWrapper = styled(LinkWrapper)`
  background: ${lightThemeColors.menuBackground};
  border-radius: 5px;
  padding: initial;
  p {
    color: ${lightThemeColors.darkColor};
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
