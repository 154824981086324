import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as UpSortIcon } from "../../assets/icons/generic/tableSortIcons/SortUp.svg";
import { ReactComponent as DownSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDown.svg";
import { ReactComponent as DefaultSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDefault.svg";
import { ReactComponent as ActionCellIcon } from "../../assets/icons/generic/ActionCellIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/sidebar/InfoIcon.svg";
import {
  getPaginationRange,
  parseSecondsInHoursMinutesSeconds,
} from "../../helper/Utils";

import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
} from "@table-library/react-table-library/table";

import { lightThemeColors } from "../../assets/styles/colors";
import { useTheme } from "@table-library/react-table-library/theme";
import moment from "moment";
import TagsTooltip from "../../components/linksComponents/TagsTootip";

import { HeaderCell } from "@table-library/react-table-library";
import { Tooltip } from "react-tooltip";

const SortSwitch = ({ sortKey, sort, onClick, noSort }) => {
  if (noSort) {
    return;
  }

  return (
    <SortSwitchElement onClick={onClick}>
      {sortKey === sort.sortKey ? (
        sort.ascendent ? (
          <UpSortIcon />
        ) : (
          <DownSortIcon />
        )
      ) : (
        <DefaultSortIcon />
      )}
    </SortSwitchElement>
  );
};

const DisplayCellBasedOnType = ({ type, value, onClick }) => {
  var diplayValue = value;

  if (!value && value != 0) {
    diplayValue = "-";
  }

  var display = (
    <CustomCell onClick={onClick}>
      <p>{diplayValue}</p>
    </CustomCell>
  );

  const leftContentCell = (
    <LeftContentCell onClick={onClick}>
      <p
        style={
          type === "Name"
            ? {
                fontWeight: "600",
                color: lightThemeColors.primaryColor,
                cursor: "pointer",
              }
            : {}
        }
      >
        {diplayValue}
      </p>
    </LeftContentCell>
  );

  const actionCell = (
    <ActionCell onClick={onClick}>
      <ActionCellIcon />
    </ActionCell>
  );

  const tagsCell = (
    <ActionCell>
      <TagsTooltip value={diplayValue} />
    </ActionCell>
  );

  switch (type) {
    case "Name":
      display = leftContentCell;
      break;
    case "Date":
      display = leftContentCell;
      break;
    case "action_cell":
      display = actionCell;
      break;
    case "tags_cell":
      display = tagsCell;
      break;

    default:
      break;
  }

  return <>{display}</>;
};

const LinksView = ({
  totalPages,
  setPage,
  page,
  setSort,
  linksWithMetrics,
  sort,
  handleEditLink,
}) => {
  const [nodes, setNodes] = useState([]);

  const createNodesFromData = () => {
    if (!linksWithMetrics) {
      return;
    }

    const mapped = linksWithMetrics.map((item) => {
      console.log(item);
      return {
        id: item.link.id,
        name: item.link.name,
        date: moment(item.link.updated_at).format("MMM DD,YYYY"),
        view: item.metrics?.view,
        open: item.metrics?.open,
        install: item.metrics?.install,
        reinstall: item.metrics?.reinstall,
        reactivation: item.metrics?.reactivation,
        tags: item.link.tags,
        time_spent: item.metrics?.avg_engagement_time,
        link: item.link,
      };
    });
    setNodes(mapped);
  };

  const data = { nodes };

  const COLUMNS = [
    {
      label: "Name",
      sortKey: "name",
      renderCell: (item) => item.name,
      type: "Name",
      background: "rgba(244, 249, 255, 1)",
      alignCenter: false,
      action: (item) => {
        handleEditLink(item);
      },
    },
    {
      label: "Date",
      sortKey: "updated_at",
      renderCell: (item) => moment(item.date).format("MMM DD, YYYY"),
      type: "Date",
      alignCenter: false,
    },
    {
      label: "Views",
      sortKey: "view",
      renderCell: (item) => item.view,
      type: "normal_cell",
      floatingInfo: "The number of times the link was opened in a web browser.",
    },
    {
      label: "Opens",
      sortKey: "open",
      renderCell: (item) => item.open,
      type: "normal_cell",
      floatingInfo:
        "This represents the number of app opens that occurred from a grovs link.",
    },
    {
      label: "Installs",
      sortKey: "install",
      renderCell: (item) => item.install,
      type: "normal_cell",
      floatingInfo: "The number of app installations generated by the link.",
    },
    {
      label: "Reinstalls",
      sortKey: "reinstall",
      renderCell: (item) => item.reinstall,
      type: "normal_cell",
      floatingInfo:
        "The number of app reinstalls generated by the link. A reinstall occurs when the app is deleted and reinstalled on the same device.",
    },
    {
      label: "Reactivations",
      sortKey: "reactivation",
      renderCell: (item) => item.reactivation,
      type: "normal_cell",
      floatingInfo:
        "The number of users who revisited the app after more than 7 days of inactivity due to a click on a link.",
    },
    {
      label: "Tags",
      sortKey: "tag",
      renderCell: (item) => item.tags,
      type: "tags_cell",
      noSort: true,
      floatingInfo: "Tags added by your team using the SDK.",
    },
    {
      label: "Avg. time spent",
      sortKey: "time_spent",
      renderCell: (item) => {
        if (item.time_spent) {
          return `${parseSecondsInHoursMinutesSeconds(item.time_spent)}`;
        } else {
          return "-";
        }
      },
      type: "normal_cell",
      floatingInfo:
        "Average time spent in the app after clicking the link (total time spent from link / number devices).",
    },
    {
      label: "",
      sortKey: "action",
      renderCell: () => <div>action</div>,
      type: "action_cell",
      noSort: true,
      action: (item) => {
        handleEditLink(item);
      },
    },
  ];

  const theme = useTheme({
    Table: `
    --data-table-library_grid-template-columns:minmax(200px,1fr) repeat(5, minmax(120px,1fr)) minmax(150px,1fr) minmax(100px,1fr) minmax(170px,170px) minmax(50px,1fr) !important;

    `,

    HeaderRow: `
        width:100%;
        background:${lightThemeColors.menuBackground};
        border-radius:5px;
        overflow:hidden;
        `,

    HeaderCell: `
        font-size:16px;
        font-weight:700;
        color:${lightThemeColors.darkColorFaded};
        padding:14px;
      flex:inherit;
      -webkit-flex:inherit;
        :first-child{
            border-radius:5px;
        }
        :last-child{
            border-radius:5px;
        }
            div{
             flex:inherit;
            }
        `,
    Cell: `
        padding:14px;
        gap:20px;
        color:${lightThemeColors.darkColorFaded};
        font-size:16px;
        font-weight:${(props) => (props.lightText ? 500 : 700)};
        border-bottom:1px solid ${lightThemeColors.menuBackground};
        `,
  });

  const checkAlign = (align) => {
    if (align === false) {
      return "start";
    } else {
      return "center";
    }
  };

  const handleChangeSort = (sortKey) => {
    setPage(1);
    setSort({
      sortKey: sortKey,
      ascendent: !sort.ascendent,
    });
  };

  useEffect(() => {
    createNodesFromData();
  }, [linksWithMetrics]);

  return (
    <Container>
      <Tooltip
        style={{ zIndex: "100", background: lightThemeColors.primaryColor }}
        id={"links-tooltip"}
        variant="info"
      />
      <LinksTableWrapper>
        <Table data={data} theme={theme} columns={COLUMNS}>
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  {COLUMNS.map((column, index) => (
                    <HeaderCellCustom
                      key={column.label}
                      center={checkAlign(column.alignCenter)}
                      background={column.background}
                      sortIcon={
                        (column.type === "action_cell" ||
                          column.type === "tags_cell") && {
                          iconDefault: null,
                          iconUp: null,
                          iconDown: null,
                        }
                      }
                    >
                      {column.floatingInfo && (
                        <>
                          <InfoIcon
                            style={{ minWidth: "13px" }}
                            data-tooltip-id={"links-tooltip"}
                            data-tooltip-content={column.floatingInfo}
                          />
                        </>
                      )}

                      {column.label}

                      <SortSwitch
                        noSort={column.noSort}
                        sortKey={column.sortKey}
                        sort={sort}
                        onClick={(e) => handleChangeSort(column.sortKey)}
                      />
                    </HeaderCellCustom>
                  ))}
                </HeaderRow>
              </Header>
              <Body>
                {tableList.map((item) => (
                  <Row key={item.id} item={item}>
                    {COLUMNS.map((column, index) => (
                      <DisplayCellBasedOnType
                        key={column.label + item.id}
                        value={column.renderCell(item)}
                        type={column.type}
                        onClick={
                          column.action
                            ? () => column.action(item)
                            : () => {
                                return;
                              }
                        }
                      />
                    ))}
                  </Row>
                ))}
              </Body>
            </>
          )}
        </Table>
        {totalPages > 1 ? (
          <PaginationWrapper>
            <>
              <PaginationBtnsWrapper>
                <PaginationBtn
                  selected
                  type="button"
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  {"<"}
                </PaginationBtn>

                {page}

                <PaginationBtn
                  type="button"
                  selected
                  disabled={page === totalPages}
                  onClick={() => setPage(page + 1)}
                >
                  {">"}
                </PaginationBtn>
              </PaginationBtnsWrapper>
            </>
          </PaginationWrapper>
        ) : (
          <Separator></Separator> // Your "else" content
        )}
      </LinksTableWrapper>
    </Container>
  );
};

export default LinksView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  gap: 20px;
`;
const Line = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const LinksTableWrapper = styled.div`
  z-index: 0;
  position: relative;
`;

const CustomCell = styled.td`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  gap: 20px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  display: flex;
  margin-left: auto;
  margin-right: auto;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: ${lightThemeColors.links.tableCellColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
`;

const LeftContentCell = styled(CustomCell)`
  justify-content: start;
  margin-left: 0;

  p {
    text-align: start;
  }
`;
const HeaderCellCustom = styled(HeaderCell)`
  background: ${(props) =>
    props.background ? props.background : "inherit"} !important;
  ${(props) =>
    props.center == "center"
      ? `
div{
display:flex;
gap:5px;
align-items:center;
    margin-left:auto;
    margin-right:auto;
    font-size:14px;
}
`
      : `
div{
    display:flex;
    gap:5px;
    align-items:center;
    margin-right:auto;
    font-size:14px;
}
`}
`;
const ActionCell = styled(CustomCell)`
  justify-content: end;
  display: flex;
  cursor: pointer;
`;

const SortSwitchElement = styled.div`
  width: 11px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;

  span {
    font-size: 16px;
    font-weight: 500;
    color: ${lightThemeColors.darkColor};
  }
`;
const PaginationBtn = styled.button`
  font-size: 16px;
  fonst-weight: 500;
  color: ${(props) =>
    props.selected
      ? lightThemeColors.darkColor
      : lightThemeColors.darkColorFaded30};
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  &:disabled {
    opacity: 0.3;
  }
`;
const PaginationBtnsWrapper = styled.div`
  display: flex;
  border: 1px solid ${lightThemeColors.darkColorFaded30};
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
`;

const Separator = styled.div`
  height: 30px;
`;
