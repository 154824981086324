import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import { ReactComponent as AndroidIcon } from "../../assets/icons/generic/AndroidIconSmall.svg";
import SubcategoryTitleWrapperWithNumber from "../../components/Generic/SubcategoryTittleWrapperWithNumber";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { openInNewTab } from "../../helper/Utils";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";

import PrimaryButton from "../../components/Generic/PrimaryButton";
import {
  ANDROID,
  APP_DELEGATE,
  COCOAPODS,
  GRADLE,
  SCENE_DELEGATE,
  SPM,
} from "../../constants/OptionsConstants";

import OptionButton from "../../components/Generic/OptionButton";
import { useExpanded } from "../../hooks/useExpanded";
import DomainLongItem from "../../components/integrationComponents/DomainLongItem";

import {
  showErrorNotification,
  showGenericError,
  showSuccessNotification,
} from "../../helper/Notifications";
import { useInstanceContext } from "../../context/useInstanceContext";
import cloneDeep from "clone-deep";
import { copyToClipboard } from "../../helper/copy";
import {
  createSearchParams,
  Link,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import _ from "lodash";
import { Tooltip } from "react-tooltip";
import { useConfigurationContext } from "../../context/useConfigurationContext";

const AndroidSDK = () => {
  const {
    selectedInstance,
    setProjectAndroidConfiguration,
    instanceConfig,
    getProjectConfiguration,
    setProjectAndroidPushConfiguration,
  } = useInstanceContext();

  const { projectRedirectsConfig } = useConfigurationContext();

  const { setLoading } = useOutletContext();
  const [expanded, expand] = useExpanded(false);
  const [sdkType, setSdkType] = useState(GRADLE);
  const [sdkMethod, setSdkMethod] = useState(APP_DELEGATE);
  const [searchParams, setSearchParams] = useSearchParams();
  const [androidConfig, setAndroidConfig] = useState(null);

  const [packageName, setPackageName] = useState("");
  const [shaInput, setShaInput] = useState("");
  const [shaList, setShaList] = useState([]);
  const [urlScheme, setUrlScheme] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const [pushNotificationCertificateFile, setPushNotificationCertificateFile] =
    useState(null);
  const [firebaseProjectId, setFirebaseProjectId] = useState("");
  const [certificate, setCertificate] = useState(null);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const divRef = useRef(null);
  const inputFileRef = useRef();

  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    setPosition({ x, y });
  };

  const handleInputFile = () => {
    setCertificate(null);
    inputFileRef.current.click();
  };

  const containerStyle = {
    maxWidth: "635px",
  };

  const SDK_TYPE_LIST = [
    {
      text: "Gradle",
      value: GRADLE,
    },
  ];

  const INTEGRATION_METHOD = [
    {
      text: "App Delegate",
      value: APP_DELEGATE,
    },
    {
      text: "Scene Delegate",
      value: SCENE_DELEGATE,
    },
  ];

  const handleGetProjectConfig = () => {
    setLoading(true);
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleSetAndroidConfig = (instanceId, enabled, identifier, sha256s) => {
    const data = {
      enabled: enabled,
      identifier: identifier,
      sha256s: sha256s,
    };

    if (
      (packageName && sha256s.length > 0) ||
      (!packageName && sha256s.length === 0)
    ) {
      setLoading(true);
      setProjectAndroidConfiguration(
        instanceId,
        data,
        (response) => {
          setAndroidConfig(response.data.config);
          showSuccessNotification("Saved");
          setLoading(false);
          handleGetProjectConfig();
        },
        () => {
          showGenericError();
          setLoading(false);
        }
      );
    } else {
      showErrorNotification(
        "You need to enter both the package name and SHA-256, before you can save the changes"
      );
      return;
    }
  };

  const handleSetAndroidPushConfig = (
    projectId,
    firebaseProjectId,
    certificateFile
  ) => {
    let formData = new FormData();
    if (firebaseProjectId !== "" && certificateFile) {
      formData.append("firebase_project_id", firebaseProjectId);
      formData.append("push_certificate", certificateFile);
    }

    setLoading(true);

    setProjectAndroidPushConfiguration(
      projectId,
      formData,
      (response) => {
        setPushNotificationCertificateFile(null);
        showSuccessNotification("Certificate changed");
        setLoading(false);
        handleGetProjectConfig();
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleSave = () => {
    let enabled = true;
    if (androidConfig) {
      enabled = androidConfig.enabled;
    }
    let detailsChanged = monitorDetailsChanged(androidConfig);
    let certificatChanged = monitorCertificateChanged(androidConfig);

    if (detailsChanged) {
      handleSetAndroidConfig(
        selectedInstance.id,
        enabled,
        packageName,
        shaList
      );
    }

    if (certificatChanged) {
      if (firebaseProjectId === "" && !pushNotificationCertificateFile) {
        showErrorNotification(
          "You need to fill in both the certificate and firebase project id, before you can save the changes."
        );
        return;
      }
      if (!packageName || shaList === 0) {
        showErrorNotification(
          `You need to fill "Register the app" section before adding the certificate`
        );
        return;
      }

      handleSetAndroidPushConfig(
        selectedInstance.id,
        firebaseProjectId,
        pushNotificationCertificateFile
      );
    }
  };

  const handleAddSha = (sha) => {
    if (sha == "") {
      return;
    }
    setShaList((prev) => {
      return [...prev, sha];
    });
    setShaInput("");
  };

  const handleRemoveSha = (index) => {
    let list = cloneDeep(shaList);
    list.splice(index, 1);
    setShaList(list);
  };

  const handleSelectSdkType = (value) => {
    setSdkType(value);
  };

  const handleSelectSdkMethod = (value) => {
    setSdkMethod(value);
  };

  const isShaValid = (input) => {
    var reg = input.replace(/[^a-zA-Z0-9 ]/g, "");
    if (reg.length === 64) {
      return false;
    } else {
      return true;
    }
  };

  const initializeValues = (config) => {
    setUrlScheme(selectedInstance.uri_scheme);
    if (!config || !config.configuration) {
      setShaList([]);
      setPackageName("");

      setCertificate(null);
      setFirebaseProjectId("");
      setPushNotificationCertificateFile(null);
    } else {
      setShaList(config.configuration.sha256s || []);
      setPackageName(config.configuration.identifier || "");
      if (config.configuration.push_configuration) {
        setCertificate(config.configuration.push_configuration.certificate);
        setFirebaseProjectId(
          config.configuration.push_configuration.firebase_project_id
        );
      } else {
        setCertificate(null);
        setFirebaseProjectId("");
        setPushNotificationCertificateFile(null);
      }
    }
  };

  const detailsChanged = (config) => {
    let detailsChanged = monitorDetailsChanged(config);
    let certificatChanged = monitorCertificateChanged(config);

    if (detailsChanged || certificatChanged) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const monitorDetailsChanged = (config) => {
    if (!config || !config.configuration) {
      if (!_.isEqual(shaList, []) || packageName != "") {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        !_.isEqual(shaList, config?.configuration.sha256s) ||
        packageName != config?.configuration.identifier
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const monitorCertificateChanged = (config) => {
    if (!config || !config.configuration) {
      if (pushNotificationCertificateFile || firebaseProjectId !== "") {
        return true;
      } else {
        return false;
      }
    } else if (
      pushNotificationCertificateFile != null &&
      firebaseProjectId !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setOpenRedirectParams = () => {
    searchParams.append("expandRedirect", true);
    return createSearchParams(searchParams).toString();
  };

  const handleSetPushNotificationCertificateFile = (e) => {
    setCertificate(null);
    setPushNotificationCertificateFile(e.currentTarget.files[0]);
  };

  const handleRemoveCertificate = () => {
    handleSetAndroidPushConfig(selectedInstance.id, "", null);
  };

  useEffect(() => {
    if (!instanceConfig) {
      return;
    }
    let found = instanceConfig.find((config) => config.platform === ANDROID);
    if (instanceConfig.length > 0 && found) {
      setAndroidConfig(found);
      initializeValues(found);
    } else {
      setAndroidConfig(null);
      initializeValues(null);
    }
  }, [instanceConfig]);

  useEffect(() => {
    detailsChanged(androidConfig);
  }, [
    shaList,
    packageName,
    urlScheme,
    androidConfig,
    pushNotificationCertificateFile,
    firebaseProjectId,
  ]);

  return (
    <Container>
      <ConfigurationSectionHeader
        icon={<AndroidIcon />}
        title={"Android SDK"}
        handleExpand={expand}
        isValid={androidConfig?.configuration?.identifier}
        showValidation
        expanded={expanded}
        customInfoText={
          androidConfig &&
          !projectRedirectsConfig?.android?.phone?.enabled &&
          isSaveDisabled && (
            <IntegrationSuccessMessage>
              <Link
                to={{
                  pathname: "/configuration",
                  search: setOpenRedirectParams(),
                }}
              >
                Enable Android app redirects{" "}
              </Link>
              so that links open directly in the app.
            </IntegrationSuccessMessage>
          )
        }
        unsavedChanges={!isSaveDisabled}
        customButton={
          !isSaveDisabled && (
            <PrimaryButton
              disabled={isSaveDisabled}
              bgVariant={lightThemeColors.background}
              styled={{ color: lightThemeColors.primaryColor }}
              text={"Save"}
              onClick={(e) => handleSave()}
            />
          )
        }
      />

      {expanded && (
        <ContentContainer>
          <Tooltip
            opacity={1}
            noArrow
            position={position}
            role="tooltip"
            border={"none"}
            style={{
              zIndex: "100",
              opacity: 1,
              background: lightThemeColors.primaryColor,
              color: lightThemeColors.background,
              padding: "5px",
              border: "none",
            }}
            id={"androidSDK-tooltip"}
          />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"1"}
                text="Register the app "
              />
            </Line>

            <Line>
              <InputFieldWithValidation
                title={"Package Name"}
                subtitle={
                  "The package name of an Android app uniquely identifies it within the Android ecosystem and can be found in the app's build.gradle or AndroidManifest.xml."
                }
                style={containerStyle}
                placeholder={"Enter the package name..."}
                label={"Show me how to get the package name"}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-get-the-package-name-LfJQaIFVe0"
                  )
                }
                inputValue={packageName}
                valid={packageName}
                handleInputChange={(e) => setPackageName(e.currentTarget.value)}
              />
            </Line>
            {shaList.length > 0 && (
              <Line>
                <h3>SHA-256</h3>
              </Line>
            )}
            {shaList.length > 0 && (
              <DomainList style={containerStyle}>
                {shaList.map((item, index) => (
                  <DomainLongItem
                    key={index}
                    item={item}
                    handleRemove={() => handleRemoveSha(index)}
                  />
                ))}
              </DomainList>
            )}
            <Line>
              <InputFieldWithValidation
                title={shaList.length == 0 ? "SHA-256" : null}
                subtitle={`Copy the SHA-256 fingerprint from "App signing certificate" under "Release Management" in your Google Play Console, and include keys from all environments.`}
                style={containerStyle}
                label={"Show me how to get the SHA-256"}
                placeholder={"Enter SHA-256..."}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-get-the-sha-256-fingerprint-03soK7YHki"
                  )
                }
                inputValue={shaInput}
                handleInputChange={(e) => setShaInput(e.currentTarget.value)}
                valid={!isShaValid(shaInput)}
                customButton={
                  <SecondaryButton
                    disabled={isShaValid(shaInput)}
                    onClick={(e) => handleAddSha(shaInput)}
                    icon={<PlusIcon />}
                    text={"Add SHA-256"}
                  />
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"2"}
                text="Add intent filters for incoming links"
              />
            </Line>
            <Line>
              <h3>AndroidManifest.xml</h3>
            </Line>
            <InfoText>
              To enable your app to open the deeplinks on Android, add an intent
              filter to your <b>launcher activity</b> in the AndroidManifest.xml
              file.
            </InfoText>
            <Line>
              <TextCodeContainer id={"androidManifestText"}>
                <p>{` <intent-filter>
     <data android:scheme="${selectedInstance.uri_scheme}" android:host="open" />
     <action android:name="android.intent.action.VIEW" />
     <category android:name="android.intent.category.DEFAULT" />
     <category android:name="android.intent.category.BROWSABLE" />
  </intent-filter>

  <intent-filter android:autoVerify="true">
     <action android:name="android.intent.action.VIEW" />
     <category android:name="android.intent.category.DEFAULT" />
     <category android:name="android.intent.category.BROWSABLE" />
     <data android:scheme="https" android:host="${selectedInstance.production.domain}" />
  </intent-filter>

  <intent-filter android:autoVerify="true">
      <action android:name="android.intent.action.VIEW" />
     <category android:name="android.intent.category.DEFAULT" />
     <category android:name="android.intent.category.BROWSABLE" />
     <data android:scheme="https" android:host="${selectedInstance.test.domain}" />
  </intent-filter>`}</p>
              </TextCodeContainer>
            </Line>
            <Line>
              <CustomLabel
                onClick={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-add-a-new-intent-filter-7e8FLlpnG4"
                  )
                }
              >
                Show me how to add a new intent filter on Android
              </CustomLabel>
              <SecondaryButton
                style={{ marginLeft: "auto" }}
                icon={<CopyIcon />}
                text={"Copy"}
                onClick={() =>
                  copyToClipboard(
                    document.getElementById("androidManifestText").innerText
                  )
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"3"}
                text="Add the SDK"
              />
            </Line>
            <Line>
              <h3>Integration method</h3>
            </Line>
            <Line>
              {SDK_TYPE_LIST.map((item, index) => (
                <OptionButton
                  key={index}
                  text={item.text}
                  selected={sdkType === item.value}
                  onClick={() => handleSelectSdkType(item.value)}
                />
              ))}
            </Line>
            <Line>
              <InputFieldWithValidation
                subtitle={
                  "Add a new Gradle package, simply include its dependency line in your module's build.gradle file, then sync Gradle to integrate it into your project."
                }
                style={containerStyle}
                readOnly
                noValidation
                label={"Show me how to add a package with Gradle"}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-add-a-package-with-gradle-m4uQXvRwoQ"
                  )
                }
                inputValue={`implementation("io.grovs:Grovs:1.0.0")`}
                customButton={
                  <SecondaryButton
                    icon={<CopyIcon />}
                    text={"Copy"}
                    onClick={() =>
                      copyToClipboard('implementation("io.grovs:Grovs:1.0.0")')
                    }
                  />
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"4"}
                text="Push notifications"
              />
            </Line>

            {certificate ? (
              <>
                <InfoText>
                  <h3 style={{ margin: 0 }}>{certificate}</h3>
                </InfoText>
                <InputFieldWithValidation
                  readOnly
                  title={"Firebase Project id"}
                  style={containerStyle}
                  noValidation
                  inputValue={firebaseProjectId}
                  handleInputChange={(e) =>
                    setFirebaseProjectId(e.currentTarget.value)
                  }
                />

                <Line>
                  <SecondaryButton
                    text={"Replace"}
                    icon={<PlusIcon />}
                    onClick={() => handleInputFile()}
                  />
                  <SecondaryButton
                    text={"Remove"}
                    icon={<RemoveIcon />}
                    variant={lightThemeColors.authentication.inputErrorColor}
                    onClick={() => handleRemoveCertificate()}
                  />
                </Line>
              </>
            ) : (
              <>
                <Line>
                  <Line>
                    <InfoText>
                      <h3 style={{ margin: 0 }}>
                        {pushNotificationCertificateFile?.name}
                      </h3>
                    </InfoText>
                    <SecondaryButton
                      text={
                        pushNotificationCertificateFile
                          ? "Replace firebase service account JSON key"
                          : "Upload firebase service account JSON key"
                      }
                      icon={<PlusIcon />}
                      onClick={() => handleInputFile()}
                    />
                  </Line>
                </Line>
                <Line>
                  <InputFieldWithValidation
                    title={"Firebase Project id"}
                    style={containerStyle}
                    noValidation
                    inputValue={firebaseProjectId}
                    handleInputChange={(e) =>
                      setFirebaseProjectId(e.currentTarget.value)
                    }
                  />
                </Line>
              </>
            )}
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={(e) => handleSetPushNotificationCertificateFile(e)}
            />
          </SubcategoryContent>

          <Separator />

          <SubcategoryContent>
            <Line>
              {" "}
              <SubcategoryTitleWrapperWithNumber
                number={"5"}
                text="Initialize the SDK"
              />
            </Line>
            {/* <Line>
              {INTEGRATION_METHOD.map((item, index) => (
                <OptionButton
                  key={index}
                  text={item.text}
                  selected={sdkMethod === item.value}
                  onClick={() => handleSelectSdkMethod(item.value)}
                />
              ))}
            </Line> */}
            <InfoText>
              In your Application class initialize the SDK in its onCreate()
              method.
            </InfoText>
            <Line>
              <TextCodeContainer
                id={"androidSDKText"}
                ref={divRef}
                onMouseMove={handleMouseMove}
              >
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >{`import io.grovs.Grovs`}</span>
                <br />
                {`class MainApplication : Application() {`}
                <br />
                <br />
                {`      override fun onCreate() {`}
                <br />
                {`            super.onCreate()`}
                <br />
                <br />
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >
                  {" "}
                  {`            Grovs.configure(this, "${selectedInstance.api_key}")`}
                </span>
                {`    }`}
                <br />
                {`}`}
              </TextCodeContainer>
            </Line>
            <Line>
              <SecondaryButton
                style={{ marginLeft: "auto" }}
                icon={<CopyIcon />}
                text={"Copy"}
                onClick={() =>
                  copyToClipboard(
                    document.getElementById("androidSDKText").innerText
                  )
                }
              />
            </Line>
            <InfoText>
              In your launcher activity add the code for handling incoming
              links.
            </InfoText>
            <Line>
              <TextCodeContainer
                id={"androidSDKText2"}
                ref={divRef}
                onMouseMove={handleMouseMove}
              >
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >{`import io.grovs.Grovs`}</span>
                <br />
                {`class MainActivity : ComponentActivity() {`}
                <br />
                <br />
                {`      override fun onStart() {`}
                <br />
                {`            super.onStart()`}
                <br />
                <br />
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >
                  {" "}
                  {`          Grovs.onStart()`}
                </span>
                {`    }`}
                <br />
                <br />
                {`      override fun onNewIntent(intent: Intent) {`}
                <br />
                {`          super.onNewIntent(intent)`}
                <br /> <br />
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >
                  {" "}
                  {`          Grovs.onNewIntent(intent)`}
                </span>
                {`    }`}
                <br />
                {`}`}
              </TextCodeContainer>
            </Line>
            <Line>
              <SecondaryButton
                style={{ marginLeft: "auto" }}
                icon={<CopyIcon />}
                text={"Copy"}
                onClick={() =>
                  copyToClipboard(
                    document.getElementById("androidSDKText2").innerText
                  )
                }
              />
            </Line>
            <InfoText>
              Register a listener OR collect the flow to receive the link and
              payload from which the app was opened.
            </InfoText>
            <Line>
              <TextCodeContainer
                id={"androidSDKText3"}
                ref={divRef}
                onMouseMove={handleMouseMove}
              >
                {`// Listen for open from link events using listeners`}
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >{`Grovs.setOnDeeplinkReceivedListener(this) { link, payload ->
      val message = "Got link from listener: $link payload: $payload"
      Log.d("Grovs", message)
}`}</span>
                <br />
                {`// OR`}
                <br />
                <br />
                {`// Listen for open from link events using kotlin coroutines`}
                <span
                  data-tooltip-id={"androidSDK-tooltip"}
                  data-tooltip-content={"Click text to copy"}
                  onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
                >{`Grovs.Companion::openedLinkDetails.flow.collect { deeplinkDetails ->
    val message = "Got link from flow: \${deeplinkDetails?.link} payload: \${deeplinkDetails?.data}"
    Log.d("Linksquared", message)
}`}</span>
              </TextCodeContainer>
            </Line>
            <Line>
              <SecondaryButton
                style={{ marginLeft: "auto" }}
                icon={<CopyIcon />}
                text={"Copy"}
                onClick={() =>
                  copyToClipboard(
                    document.getElementById("androidSDKText3").innerText
                  )
                }
              />
            </Line>
            <CustomLabel
              onClick={() =>
                openInNewTab(
                  "https://docs.grovs.io/s/docs/doc/android-cn7sFj1MEZ"
                )
              }
            >
              Show me more details about the SDK and it’s methods
            </CustomLabel>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default AndroidSDK;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
  h3 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  max-width: 635px;
`;

const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  overflow: auto;
  white-space: pre;
  span {
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
    &:hover {
      background: ${lightThemeColors.sidebar.linkBg};
    }
  }
  p {
    white-space: pre;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 500;
    color: ${lightThemeColors.darkColorFaded60};
  }
`;

const DomainList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const CustomLabel = styled.label`
  margin-top: -20px;
  cursor: pointer;
  color: ${lightThemeColors.inputLabelColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const IntegrationSuccessMessage = styled.p`
font-size:14px;
color:${lightThemeColors.darkColor};
a{
text-decoration:none;
color:${lightThemeColors.primaryColor};}
}
`;
