import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { lightThemeColors } from "../../assets/styles/colors";
import Select from "react-select";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
} from "@floating-ui/react";

const formatOptionLabel = ({ value, label, customAbbreviation }) => (
  <CustomListElementContainer>
    <p>{label}</p>
    <input readOnly type="checkbox" />
  </CustomListElementContainer>
);

const CustomSelectItem = ({ label, value, selectedMetrics, onClick }) => {
  return (
    <CustomListElementContainer onClick={onClick}>
      <p>{label}</p>
      <input readOnly checked={selectedMetrics} type="checkbox" />
    </CustomListElementContainer>
  );
};

const selectCustomStyles = {
  container: (provided) => ({
    ...provided,
    "&:focus-visible": {
      outline: "none",
    },
    "font-size": "14px",
  }),

  control: (provided) => ({
    ...provided,
    borderRadius: "5px",
    borderColor: lightThemeColors.darkColorFullFaded,
    outline: "none",
    "&:hover": {
      borderColor: "none",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "16px",
    fontWeight: "500",
    color: lightThemeColors.darkColorFaded,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: lightThemeColors.darkColorFaded30,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

const AddMetrics = ({
  dynamicMetrics,
  selectedMetrics,
  setSelectedMetrics,
  platforms,
  setPlatforms,
  appVersions,
  setAppVersions,
  buildVersions,
  setBuildVersions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();

  const metricsList = [
    {
      value: "view",
      label: "Views",
    },
    {
      value: "open",
      label: "Opens",
    },
    {
      value: "install",
      label: "Installs",
    },
    {
      value: "reinstall",
      label: "Reinstalls",
    },
    {
      value: "reactivation",
      label: "Reactivations",
    },
    {
      value: "avg_engagement_time",
      label: "Sesion Time",
    },
    {
      value: "user_referred",
      label: "User referred",
    },
  ];

  const handleSelectMetrics = (metric) => {
    let found = selectedMetrics.find((item) => item.value == metric.value);

    if (found) {
      let filtered = selectedMetrics.filter(
        (item) => item.value !== found.value
      );
      setSelectedMetrics(filtered);
    } else {
      setSelectedMetrics([...selectedMetrics, metric]);
    }
  };

  const displayMetricsSelect = (metrics) => {
    if (!metrics) {
      return;
    }

    const keyValues = [];

    const keys = Object.keys(metrics);
    const values = Object.values(metrics);

    keys.forEach((key, index) => {
      let obj = {};
      obj.menuTitle = key;
      obj.values = values[index].map((value) => {
        if (key === "app_versions") {
          return {
            value: value,
            label: `V ${value}`,
          };
        }
        if (key === "platforms") {
          if (value === "ios") {
            return { value: value, label: "iOS" };
          } else
            return {
              value: value,
              label: value.charAt(0).toUpperCase() + value.slice(1),
            };
        } else {
          return {
            value: value,
            label: value,
          };
        }
      });
      keyValues.push(obj);
    });

    return keyValues;
  };

  const mapMenuTitle = (menuTitle) => {
    switch (menuTitle) {
      case "platforms":
        return "Platforms";

      case "app_versions":
        return "App Versions";

      case "builds":
        return "Builds";

      default:
        break;
    }
  };

  const displaySelectBasedOnMenu = (metrics) => {
    let display = <></>;

    switch (metrics.menuTitle) {
      case "platforms":
        display = (
          <Select
            controlShouldRenderValue={false}
            styles={selectCustomStyles}
            placeholder={mapMenuTitle(metrics.menuTitle)}
            options={metrics.values}
            onChange={(e) => setPlatforms(e)}
            isMulti
            value={platforms}
            formatOptionLabel={formatOptionLabel}
            isClearable={false}
            isSearchable={false}
          />
        );
        break;
      case "app_versions":
        display = (
          <Select
            controlShouldRenderValue={false}
            styles={selectCustomStyles}
            placeholder={mapMenuTitle(metrics.menuTitle)}
            options={metrics.values}
            onChange={(e) => setAppVersions(e)}
            isMulti
            value={appVersions}
            formatOptionLabel={formatOptionLabel}
            isClearable={false}
            isSearchable={false}
          />
        );
        break;
      case "builds":
        display = (
          <Select
            controlShouldRenderValue={false}
            styles={selectCustomStyles}
            placeholder={mapMenuTitle(metrics.menuTitle)}
            options={metrics.values}
            onChange={(e) => setBuildVersions(e)}
            isMulti
            value={buildVersions}
            formatOptionLabel={formatOptionLabel}
            isClearable={false}
            isSearchable={false}
          />
        );
        break;

      default:
        break;
    }
    return display;
  };

  return (
    <Container>
      <AddMetricsButton ref={refs.setReference} {...getReferenceProps()}>
        <PlusIcon />
        Add metrics
      </AddMetricsButton>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <MetricsList
            ref={refs.setFloating}
            style={floatingStyles}
            aria-labelledby={headingId}
            {...getFloatingProps()}
          >
            {metricsList.map((metric, index) => (
              <CustomSelectItem
                key={index}
                label={metric.label}
                value={metric.value}
                selectedMetrics={selectedMetrics.find(
                  (item) => item.value === metric.value
                )}
                onClick={() => handleSelectMetrics(metric)}
              />
            ))}
            {/* <Separator /> */}

            {/* {displayMetricsSelect(dynamicMetrics).map((metrics, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {displaySelectBasedOnMenu(metrics)}
                <Separator />
              </div>
            ))} */}
          </MetricsList>
        </FloatingFocusManager>
      )}
    </Container>
  );
};

export default AddMetrics;
const Container = styled.div`
  position: relative;
`;
const MetricsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 10px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.primaryColor};
  background: ${lightThemeColors.background};
  width: 100%;
  min-height: 200px;
  min-width: 180px;
  z-index: 5;
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;

const AddMetricsButton = styled.button`
min-width:40px;
display:flex;
align-items:center;
justify-content:center;
width:fit-content;
gap:2px;
background:white;
padding:6px 8px 6px 6px;
border:1px solid ${lightThemeColors.primaryColor};
border-radius:5px;
font-size: 14px;
font-weight: 600;
line-height: 14px;
text-align: center;
color:${lightThemeColors.primaryColor};
cursor:pointer;
&:disabled{
    opacity:0.3;
}
svg{
    color:${lightThemeColors.primaryColor};
    height:16px;
    
`;

const CustomListElementContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${lightThemeColors.darkColorFaded};
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  input {
    margin-left: auto;
  }
`;
