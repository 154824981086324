import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const AudienceInvited = ({ members }) => {
  console.log("members", members);
  return (
    <Container>
      <Header>
        <Cell>
          <p>ID</p>
        </Cell>
        <Cell>
          <p>SDK Identifier</p>
        </Cell>
      </Header>
      <List>
        {members.map((member) => (
          <ListElement key={member.uuid}>
            <Cell>
              <p>{member.uuid}</p>
            </Cell>
            <Cell>
              <p>{member.sdk_identifier}</p>
            </Cell>
          </ListElement>
        ))}
      </List>
    </Container>
  );
};

export default AudienceInvited;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #e7e9ec;
  border-left: 1px solid #e7e9ec;
  border-right: 1px solid #e7e9ec;
  border-radius: 4px;
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  background: ${lightThemeColors.menuBackground};
  gap: 5px;
  border-bottom: 1px solid #e7e9ec;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListElement = styled.div`
  display: flex;
  gap: 5px;
  border-bottom: ${(props) => (props.header ? "" : `1px solid #E7E9EC`)};
`;
const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;

  p {
    font-size: 16px;
    font-weight: 600;
    color: ${lightThemeColors.darkColor};
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
`;
