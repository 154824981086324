import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CheckSvg } from "../../assets/icons/generic/GreenCheckSquared.svg";
import MuaProgress from "./MuaProgress";
import PrimaryButton from "../Generic/PrimaryButton";
import SecondaryButton from "../Generic/SecondaryButton";
import DeleteConfirmationChild from "../Generic/DeleteConfirmationWithChild";
import { CANCEL_PLAN } from "../../constants/OptionsConstants";
import AdminRoleRequired from "../../helper/AdminRoleRequired";

const ListElement = ({ text }) => {
  return (
    <ElementWrapepr>
      <CheckSvg />
      <p>{text}</p>
    </ElementWrapepr>
  );
};

const PlanContent = ({
  plan,
  setIsModalOpen,
  setIsCancelConfirmationOpen,
  isCancelCOnfirmationOpen,
  handleCancelSubscription,
}) => {
  console.log("plan", plan);

  return (
    <Container>
      <TitleWrapper>
        <h1> {plan ? "Scale Up" : "Free plan"}</h1>
      </TitleWrapper>

      <MuaProgress plan={plan} />

      <AdminRoleRequired>
        <Line>
          {plan && (
            <DeleteConfirmationChild
              open={isCancelCOnfirmationOpen}
              setOpen={setIsCancelConfirmationOpen}
              action={handleCancelSubscription}
              variant={CANCEL_PLAN}
            >
              <SecondaryButton
                variant={lightThemeColors.darkColorFaded30}
                text={"Cancel Plan"}
                onClick={() => setIsCancelConfirmationOpen(true)}
              />
            </DeleteConfirmationChild>
          )}
          <PrimaryButton
            text={"Change Plan"}
            onClick={() => setIsModalOpen(true)}
          />
        </Line>
      </AdminRoleRequired>
    </Container>
  );
};

export default PlanContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  h1 {
    color: ${lightThemeColors.primaryColor};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const PointList = styled.ul`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

const ElementWrapepr = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
